import BasePage from "../modules/basePage";
import "../../css/pages/franchiseHome.scss";

class franchiseHomePage extends BasePage {
    constructor(...opts) {
        super(...opts);
    }

    init() {
        super.init();
    }

    onReady() {
        super.onReady();
    }
}

new franchiseHomePage();
